import { useForm } from "react-hook-form";
import { createNewSection } from "../../services/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const defaultValues = {
  id: "",
  modelId: "",
  order: ""
};

export const NewCreate = () => {
  const { register, handleSubmit } = useForm({ defaultValues });

  const notify = () => toast.success("Success!");

  const onFormSubmit = (data) => {
    data.order = +data.order;
    createNewSection({ ...data });

    notify();
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="panel__form">
      <div className="panel__input--wrapper">
        <input
          type="text"
          className="model__panel--input"
          {...register("id", { required: true })}
          placeholder="ID"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("modelId", { required: true })}
          placeholder="Model Id"
        />

        <input
          type="text"
          className="model__panel--input"
          {...register("order", { required: true })}
          placeholder="Order"
        />
      </div>
      <button
        type="submit"
        className="model__panel--button"
        style={{ margin: "25px auto 0" }}
      >
        Save
      </button>
    </form>
  );
};
