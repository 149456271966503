import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { createBlock } from "../../services/axios.js";

export const CreateBlock = () => {
  const defaultValues = {
    id: "",
    title: "",
    description: "",
    fastFilter: "",
    filterName: "",
    filterValue: "",
    review1name: "",
    review1text: "",
    review2name: "",
    review2text: "",
  };
  const { register, handleSubmit } = useForm({ defaultValues });
  const [image, setImage] = useState("");
  const [modelsIds, setModelsIds] = useState([""]);

  const notify = () => toast.success("Success!");

  const getPreviewBuffer = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(
        `https://princesss.store/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setImage(response.data.imageUrl);
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };

  const onModelIdChange = (event, index) => {
    setModelsIds((prevState) => {
      const array = [...prevState];
      array[index] = event.target.value;
      return array;
    });
  };
  const onAddModelClick = () => {
    setModelsIds((prevState) => [...prevState, ""]);
  };

  const onFormSubmit = (data) => {
    const {
      id,
      title,
      description,
      filterName,
      filterValue,
      review1name,
      review1text,
      review2name,
      review2text,
      fastFilter,
    } = data;
    const blockData = {
      id,
      title,
      description,
      fastFilter,
      filter: { name: filterName, value: filterValue },
      models: modelsIds.filter((el) => el.trim() !== ""),
      reviews: [
        { name: review1name, reviewText: review1text },
        { name: review2name, reviewText: review2text },
      ],
      image,
    };
    createBlock(blockData);
    notify();
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="panel__form">
      <div className="panel__input--wrapper">
        <input
          type="text"
          className="model__panel--input"
          {...register("id", { required: true })}
          placeholder="ID"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("title", { required: true })}
          placeholder="Title"
        />
        <div style={{ position: "relative" }}>
          <input
            type="file"
            className="model__panel--input"
            style={{ opacity: 0, zIndex: "111" }}
            onChange={(e) => {
              getPreviewBuffer(e.target.files[0]);
            }}
          />
          {image ? (
            <div className="model__panel--file" style={{ width: "250px" }}>
              UPLOADED✅
              <img
                src={image}
                alt="preview"
                className="panel__image--preview"
              />
            </div>
          ) : (
            <div className="model__panel--file">preview image</div>
          )}
        </div>
        <input
          type="text"
          className="model__panel--input"
          {...register("description", { required: true })}
          placeholder="Description"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("fastFilter", { required: true })}
          placeholder="FastFilter ID"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("filterName", { required: false })}
          placeholder="filterName"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("filterValue", { required: false })}
          placeholder="filterValue"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("review1name", { required: true })}
          placeholder="review1name"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("review1text", { required: true })}
          placeholder="review1text"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("review2name", { required: true })}
          placeholder="review2name"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("review2text", { required: true })}
          placeholder="review2text"
        />
        {modelsIds &&
          modelsIds.map((el, index) => (
            <input
              type="text"
              key={index}
              className="model__panel--input"
              placeholder={`model ${index + 1}`}
              value={modelsIds[index]}
              onChange={(e) => onModelIdChange(e, index)}
            />
          ))}
        <button
          type={"button"}
          className="model__panel--input"
          onClick={onAddModelClick}
        >
          Додати модель
        </button>
      </div>

      <button
        type="submit"
        className="model__panel--button"
        style={{ margin: "25px auto 0" }}
      >
        Create
      </button>
    </form>
  );
};
