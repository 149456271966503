import { useEffect, useState } from "react";
import { NewCreate } from "../../components/NewCreate/NewCreate";
import { NewEdit } from "../../components/NewEdit/NewEdit";
import { ToastContainer, toast } from "react-toastify";
import {
  getNewSection,
  getNewSections,
  hideNewSection,
  tryLogin,
} from "../../services/axios";

export const NewPanel = () => {
  const [page, setPage] = useState("base");
  const [newSections, setNewSections] = useState([]);
  const [newSection, setNewSection] = useState(null);

  useEffect(() => {
    const tryGetAccess = async () => {
      const password = localStorage.getItem("passwordToAdminPanel");
      if (password) {
        if ((await tryLogin(password)) === "denied") {
          window.location.href = "/";
        }
      } else {
        window.location.href = "/";
      }
    };

    tryGetAccess();
  }, []);

  const notify = () => toast.success("Success!");

  useEffect(() => {
    const fetchNewSections = async () => {
      const fetchedNewSections = await getNewSections(false);
      // fetchedNewSections.sort((a, b) => {
      //   return parseInt(a.id) - parseInt(b.id);
      // });
      setNewSections(fetchedNewSections);
    };
    fetchNewSections();
  }, []);

  return (
    <div className="container" style={{ minHeight: "100vh", padding: "0" }}>
      {page === "base" ? (
        <>
          <button
            type="button"
            className="model__panel--button"
            onClick={() => {
              setPage("create");
            }}
          >
            Створити
          </button>
          <div className="panel__list">
            {newSections?.map(({ _id, id, modelId, order, show }) => (
              <div className="panel__item--wrapper" key={_id}>
                <div
                  style={
                    show
                      ? { backgroundColor: "#fcb25f" }
                      : {
                          backgroundColor: "rgb(189, 193, 198)",
                          border: "rgb(93, 93, 93)",
                        }
                  }
                  key={_id}
                  className="panel__item"
                  onClick={async () => {
                    setNewSection(await getNewSection(_id));
                    setPage("edit");
                  }}
                >
                  <div className="panel__id--wrapper">
                    <p className="panel__id">{id}</p>
                  </div>
                  <p className="panel__name">Model id: {modelId}</p>
                  <p className="panel__name">order : {order}</p>
                </div>
                <button
                  className="panel__button--hide"
                  onClick={() => {
                    hideNewSection(_id, !show);
                    notify();
                  }}
                >
                  {show ? "Active" : "Hidden"}
                </button>
              </div>
            ))}
          </div>
        </>
      ) : page === "create" ? (
        <NewCreate />
      ) : (
        <NewEdit newSection={newSection} />
      )}
      <ToastContainer />
    </div>
  );
};
