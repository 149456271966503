import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GTM = {
  pushEvent: (eventName, params) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...params,
    });
  },
};

export const useGTMEvents = () => {
  const location = useLocation();

  useEffect(() => {
    GTM.pushEvent("page_view", {
      page_path: location.pathname,
    });
  }, [location]);

  const addToCartGmt = (item) => {
    GTM.pushEvent("add_to_cart", {
      item_id: item.id,
      item_name: item.name,
      item_category: item.category,
      price: item.price,
      quantity: item.quantity,
    });
  };

  const removeFromCart = (item) => {
    GTM.pushEvent("remove_from_cart", {
      item_id: item.id,
      item_name: item.name,
      item_category: item.category,
      price: item.price,
      quantity: item.quantity,
    });
  };

  const viewCart = (items) => {
    GTM.pushEvent("view_cart", {
      items: items.map((item) => ({
        item_id: item.id,
        item_name: item.name,
        price: item.price,
        quantity: item.quantity,
      })),
    });
  };

  const beginCheckout = (transaction) => {
    GTM.pushEvent("begin_checkout", {
      transaction_id: transaction.id,
      value: transaction.value,
      currency: "UAH",
    });
  };

  const purchase = (transaction) => {
    GTM.pushEvent("purchase", {
      transaction_id: transaction.id,
      value: transaction.value,
      currency: "UAH",
      items: transaction.items.map((item) => ({
        item_id: item.id,
        item_name: item.name,
        price: item.price,
        quantity: item.quantity,
        currency: "UAH",
      })),
    });
  };

  const paymentSuccess = (transaction) => {
    GTM.pushEvent("payment_success", {
      transaction_id: transaction.id,
      value: transaction.value,
      payment_method: transaction.payment_method,
    });
  };

  const paymentFailure = (transaction, error) => {
    GTM.pushEvent("payment_failure", {
      transaction_id: transaction.id,
      error_message: error,
    });
  };

  const viewItem = (item) => {
    GTM.pushEvent("view_item", {
      item_id: item.id,
      item_name: item.name,
      item_category: item.category,
      price: item.price,
    });
  };

  const clickItem = (item) => {
    GTM.pushEvent("click_item", {
      item_id: item.id,
      item_name: item.name,
    });
  };

  const discountPopup = (popup) => {
    GTM.pushEvent("discount_popup", {
      discount_value: popup.value,
      currency: "UAH",
      popup_type: popup.type,
      interaction: popup.interaction,
    });
  };

  return {
    addToCartGmt,
    removeFromCart,
    viewCart,
    beginCheckout,
    purchase,
    paymentSuccess,
    paymentFailure,
    viewItem,
    clickItem,
    discountPopup,
  };
};
