import { Routes, Route } from "react-router-dom";
import { useEffect, useState, lazy, Suspense } from "react";
import WOW from "wowjs";
import { models } from "./models/models.js";
import Menu from "./components/Menu/Menu";
import Preloader from "./components/Preloader/Preloader";
import { Present } from "./components/Popups/Present";
import { Telegram } from "./components/Popups/Telegram";
import { FreeDelivery } from "./components/Popups/FreeDelivery";
import Sms from "./pages/Sms/Sms.jsx";
import { HeaderPanel } from "./components/HeaderPanel/HeaderPanel.jsx";
import { ModelPanel } from "./pages/ModelPanel/ModelPanel.jsx";
import { NewPanel } from "./pages/NewPanel/NewPanel.jsx";
import { Wheel } from "./components/Wheel/Wheel.jsx";
import { SmsForm } from "./components/SmsForm/SmsForm.jsx";
import { SmsSent } from "./components/SmsSent/SmsSent.jsx";
import { WheelUpdate } from "./pages/WheelUpdate/WheelUpdate.jsx";
import { FilterPanel } from "./components/FilterPanel/FilterPanel.jsx";
import { Cart } from "./components/Cart/Cart.jsx";
import { PromocodePanel } from "./pages/PromocodePanel/PromocodePanel.jsx";
import { ReviewsPanel } from "./pages/ReviewsPanel/ReviewsPanel.jsx";
import { BlocksPanel } from "./pages/BlocksPanel/BlocksPanel.jsx";
import Checkout from "./pages/Checkout/Checkout.jsx";
import { FastfilterPanel } from "./pages/FastfilterPanel/FastfilterPanel.jsx";
import { PromocodePopup } from "./components/Popups/PromocodePopup.jsx";
import Callback from "./components/Callback/Callback.jsx";
import { OneClickCart } from "./components/Cart/OneClickCart/OneClickCart.jsx";

const Home = lazy(() => import("./pages/Home/Home"));
const Model = lazy(() => import("./pages/Model/Model"));
const Thanks = lazy(() => import("./pages/Thanks/Thanks"));
const Modal = lazy(() => import("./components/Modal/Modal"));
const Exchange = lazy(() => import("./pages/Exchange/Exchange"));
const CatalogPage = lazy(() => import("./pages/CatalogPage/CatalogPage"));
const Delivery = lazy(() => import("./pages/Delivery/Delivery"));
const Terms = lazy(() => import("./pages/Terms/Terms"));
const ReviewsPage = lazy(() => import("./pages/ReviewsPage/ReviewsPage"));

function App() {
  const [isHidden, setIsHidden] = useState("");
  const [from, setFrom] = useState("");
  const [price, setPrice] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPresentPopupOpen, setIsPresentPopupOpen] = useState(false);
  const [isTelegramPopupOpen, setIsTelegramPopupOpen] = useState(false);
  const [isFreeDeliveryPopupOpen, setIsFreeDeliveryPopupOpen] = useState(false);
  const [id, setId] = useState("1");
  const [isPrizeAvailable, setIsPrizeAvailable] = useState(true);
  const [isWheelModalOpen, setIsWheelModalOpen] = useState(false);
  const [isSmsFormOpen, setIsSmsFormOpen] = useState(false);
  const [isSmsSent, setIsSmsSent] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [fastCartOpen, setFastCartOpen] = useState(false);
  const [isPromocodePopupOpen, setIsPromocodePopupOpen] = useState(false);

  useEffect(() => {
    const THREE_DAY_IN_MS = 3 * 24 * 60 * 60 * 1000;
    const lastClickTime = localStorage.getItem("lastClickTime");
    if (lastClickTime) {
      const timePassed = Date.now() - +lastClickTime;
      if (timePassed < THREE_DAY_IN_MS) {
        setIsPrizeAvailable(false);
      } else {
        setIsPrizeAvailable(true);
        localStorage.removeItem("isWheelAlreadyRunned");
      }
    }
  }, []);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("presentPopupOpen")) {
  //     if (localStorage.getItem("wheelPopupByTimer")) {
  //       if (!isPresentPopupOpen) {
  //         if (localStorage.getItem("telegramPopupOpen")) {
  //           return;
  //         } else {
  //           setTimeout(() => {
  //             setIsTelegramPopupOpen(true);
  //             localStorage.setItem("telegramPopupOpen", true);
  //           }, 180000);
  //         }
  //       }
  //     } else {
  //       setTimeout(() => {
  //         if (localStorage.getItem("wheelPopupByTimer")) {
  //           setIsWheelModalOpen(true);
  //           localStorage.setItem("wheelPopupByTimer", true);
  //         }
  //       }, 60000);
  //     }
  //   } else {
  //     setTimeout(() => {
  //       setIsPresentPopupOpen(true);
  //       localStorage.setItem("presentPopupOpen", true);
  //     }, 120000);
  //   }
  // }, [
  //   isFreeDeliveryPopupOpen,
  //   isPresentPopupOpen,
  //   isWheelModalOpen,
  //   isSmsSent,
  //   isSmsFormOpen,
  // ]);

  // useEffect(() => {
  //   if (localStorage.getItem("wheelPopupByTimer")) {
  //     if (localStorage.getItem("presentPopupOpen")) {
  //       if (localStorage.getItem("telegramPopupOpen")) {
  //         return;
  //       } else {
  //         setTimeout(() => {
  //           setIsTelegramPopupOpen(true);
  //           localStorage.setItem("telegramPopupOpen", true);
  //         }, 60000);
  //       }
  //     } else {
  //       setTimeout(() => {
  //         setIsPresentPopupOpen(true);
  //         localStorage.setItem("presentPopupOpen", true);
  //       }, 60000);
  //     }
  //   } else {
  //     if (!isPresentPopupOpen) {
  //       setTimeout(() => {
  //         setIsWheelModalOpen(true);
  //         localStorage.setItem("wheelPopupByTimer", true);
  //       }, 60000);
  //     }
  //   }
  // }, [
  //   isFreeDeliveryPopupOpen,
  //   isPresentPopupOpen,
  //   isWheelModalOpen,
  //   isSmsSent,
  //   isSmsFormOpen,
  // ]);

  useEffect(() => {
    const showPromocodesPopup = () => {
      if (localStorage.getItem("receivedPromocodes")) {
        return;
      }
      if (!sessionStorage.getItem("wasOpenPromocodePopup")) {
        setTimeout(() => {
          setIsPromocodePopupOpen(true);
        }, 10000);
      }
    };
    showPromocodesPopup();
  }, []);

  useEffect(() => {
    const parameters = new URLSearchParams(document.location.search);

    let pixel = parameters.get("pixel");
    let fbclid = parameters.get("fbclid");
    let utm_source = parameters.get("utm_source");
    let utm_medium = parameters.get("utm_medium");
    let utm_term = parameters.get("utm_term");
    let utm_content = parameters.get("utm_content");
    let utm_campaign = parameters.get("utm_campaign");

    if (pixel) {
      localStorage.setItem("pixel", pixel);
    } else {
      pixel = localStorage.getItem("pixel") ?? "";
    }

    if (fbclid) {
      localStorage.setItem("fbclid", fbclid);
    } else {
      fbclid = localStorage.getItem("fbclid") ?? "";
    }

    if (utm_source) {
      localStorage.setItem("utm_source", utm_source);
    } else {
      utm_source = localStorage.getItem("utm_source") ?? "";
    }

    if (utm_medium) {
      localStorage.setItem("utm_medium", utm_medium);
    } else {
      utm_medium = localStorage.getItem("utm_medium") ?? "";
    }

    if (utm_term) {
      localStorage.setItem("utm_term", utm_term);
    } else {
      utm_term = localStorage.getItem("utm_term") ?? "";
    }

    if (utm_content) {
      localStorage.setItem("utm_content", utm_content);
    } else {
      utm_content = localStorage.getItem("utm_content") ?? "";
    }

    if (utm_campaign) {
      localStorage.setItem("utm_campaign", utm_campaign);
    } else {
      utm_campaign = localStorage.getItem("utm_campaign") ?? "";
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="cart" element={<Checkout setCartOpen={setCartOpen} />} />
        <Route
          path="/"
          element={
            <Suspense fallback={<Preloader />}>
              <Home
                setIsHidden={setIsHidden}
                setFrom={setFrom}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                setId={setId}
                id={id}
                isPrizeAvailable={isPrizeAvailable}
                setIsSmsFormOpen={setIsSmsFormOpen}
                setIsWheelModalOpen={setIsWheelModalOpen}
                setIsSmsSent={setIsSmsSent}
                setCartOpen={setCartOpen}
                setFastCartOpen={setFastCartOpen}
              />
            </Suspense>
          }
        />
        <Route
          path="/test"
          element={
            <Suspense fallback={<Preloader />}>
              <Home
                setIsHidden={setIsHidden}
                setFrom={setFrom}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                setId={setId}
                id={id}
                isPrizeAvailable={isPrizeAvailable}
                setIsSmsFormOpen={setIsSmsFormOpen}
                setIsWheelModalOpen={setIsWheelModalOpen}
                setIsSmsSent={setIsSmsSent}
                setCartOpen={setCartOpen}
              />
            </Suspense>
          }
        />

        <Route
          path="/catalog"
          element={
            <Suspense fallback={<Preloader />}>
              <CatalogPage
                setIsHidden={setIsHidden}
                setIsOpen={setIsHidden}
                setFrom={setFrom}
                setPrice={setPrice}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                setId={setId}
                id={id}
                setCartOpen={setCartOpen}
                setFastCartOpen={setFastCartOpen}
              />
            </Suspense>
          }
        />
        {models?.map(({ path }) => (
          <Route
            path={`/modeli/${path}`}
            key={path}
            element={
              <Suspense fallback={<Preloader />}>
                <Model
                  setIsOpen={setIsHidden}
                  modelName={path}
                  setFrom={setFrom}
                  setPrice={setPrice}
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                  setId={setId}
                  id={id}
                  setCartOpen={setCartOpen}
                  setFastCartOpen={setFastCartOpen}
                />
              </Suspense>
            }
          />
        ))}
        <Route
          path="/thanks"
          element={
            <Suspense fallback={<Preloader />}>
              <Thanks
                setIsHidden={setIsHidden}
                setFrom={setFrom}
                setPrice={setPrice}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                setId={setId}
                id={id}
              />
            </Suspense>
          }
        />
        <Route
          path="/exchange"
          element={
            <Suspense fallback={<Preloader />}>
              <Exchange
                setIsHidden={setIsHidden}
                setFrom={setFrom}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                setId={setId}
                id={id}
                setCartOpen={setCartOpen}
              />
            </Suspense>
          }
        />
        <Route
          path="/delivery"
          element={
            <Suspense fallback={<Preloader />}>
              <Delivery
                setIsHidden={setIsHidden}
                setFrom={setFrom}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                setId={setId}
                id={id}
                setCartOpen={setCartOpen}
              />
            </Suspense>
          }
        />
        <Route
          path="/terms"
          element={
            <Suspense fallback={<Preloader />}>
              <Terms
                setIsHidden={setIsHidden}
                setFrom={setFrom}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                setId={setId}
                id={id}
                setCartOpen={setCartOpen}
              />
            </Suspense>
          }
        />
        <Route
          path="/reviews"
          element={
            <Suspense fallback={<Preloader />}>
              <ReviewsPage
                setIsHidden={setIsHidden}
                setFrom={setFrom}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                setId={setId}
                id={id}
                setCartOpen={setCartOpen}
              />
            </Suspense>
          }
        />
        <Route
          path="/sms"
          element={
            <Suspense fallback={<Preloader />}>
              <Sms />
            </Suspense>
          }
        />
        <Route
          path="/wheel"
          element={
            <Suspense fallback={<Preloader />}>
              <WheelUpdate
                setIsHidden={setIsHidden}
                setFrom={setFrom}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                id={id}
                isPrizeAvailable={isPrizeAvailable}
                setIsSmsFormOpen={setIsSmsFormOpen}
                setIsWheelModalOpen={setIsWheelModalOpen}
                setIsSmsSent={setIsSmsSent}
              />
            </Suspense>
          }
        />
        <Route
          path="/smk147uoljowb/model"
          element={
            <Suspense fallback={<Preloader />}>
              <div style={{ backgroundColor: "#22252b" }}>
                <HeaderPanel />
                <ModelPanel />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/smk147uoljowb/new"
          element={
            <Suspense fallback={<Preloader />}>
              <div style={{ backgroundColor: "#22252b" }}>
                <HeaderPanel />
                <NewPanel />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/smk147uoljowb/filter"
          element={
            <Suspense fallback={<Preloader />}>
              <div style={{ backgroundColor: "#22252b" }}>
                <HeaderPanel />
                <FilterPanel />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/smk147uoljowb/promocode"
          element={
            <Suspense fallback={<Preloader />}>
              <div style={{ backgroundColor: "#22252b" }}>
                <HeaderPanel />
                <PromocodePanel />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/smk147uoljowb/reviews"
          element={
            <Suspense fallback={<Preloader />}>
              <div style={{ backgroundColor: "#22252b" }}>
                <HeaderPanel />
                <ReviewsPanel />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/smk147uoljowb/blocks"
          element={
            <Suspense fallback={<Preloader />}>
              <div style={{ backgroundColor: "#22252b" }}>
                <HeaderPanel />
                <BlocksPanel />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/smk147uoljowb/fastFilter"
          element={
            <Suspense fallback={<Preloader />}>
              <div style={{ backgroundColor: "#22252b" }}>
                <HeaderPanel />
                <FastfilterPanel />
              </div>
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<Preloader />}>
              <Home
                setIsHidden={setIsHidden}
                setFrom={setFrom}
                setId={setId}
                isPrizeAvailable={isPrizeAvailable}
                setIsSmsFormOpen={setIsSmsFormOpen}
                setIsWheelModalOpen={setIsWheelModalOpen}
                setIsSmsSent={setIsSmsSent}
                setCartOpen={setCartOpen}
                setFastCartOpen={setFastCartOpen}
              />
            </Suspense>
          }
        />
      </Routes>
      <Suspense fallback={<Preloader />}>
        <Modal
          isHidden={isHidden}
          setIsHidden={setIsHidden}
          from={from}
          price={price}
          id={id}
        />
      </Suspense>
      {isPromocodePopupOpen && (
        <PromocodePopup setIsOpen={setIsPromocodePopupOpen} />
      )}
      {/*{isPresentPopupOpen && (*/}
      {/*  <Present*/}
      {/*    setIsPresentPopupOpen={setIsPresentPopupOpen}*/}
      {/*    from={from}*/}
      {/*    id={id}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{isFreeDeliveryPopupOpen && (*/}
      {/*  <FreeDelivery setIsFreeDeliveryPopupOpen={setIsFreeDeliveryPopupOpen} />*/}
      {/*)}*/}
      {/*{isTelegramPopupOpen && (*/}
      {/*  <Telegram setIsTelegramPopupOpen={setIsTelegramPopupOpen} />*/}
      {/*)}*/}
      {/*{isMenuOpen && (*/}
      {/*  <Menu setIsMenuOpen={setIsMenuOpen} setIsHidden={setIsHidden} />*/}
      {/*)}*/}
      {/*{isWheelModalOpen && (*/}
      {/*  <Wheel*/}
      {/*    setIsWheelModalOpen={setIsWheelModalOpen}*/}
      {/*    setIsSmsFormOpen={setIsSmsFormOpen}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{isSmsFormOpen && (*/}
      {/*  <SmsForm*/}
      {/*    setIsSmsFormOpen={setIsSmsFormOpen}*/}
      {/*    setIsPrizeAvailable={setIsPrizeAvailable}*/}
      {/*    setIsSmsSent={setIsSmsSent}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{isSmsSent && <SmsSent setIsSmsSent={setIsSmsSent} />}*/}
      {cartOpen && <Cart setCartOpen={setCartOpen} />}
      {fastCartOpen && <OneClickCart setFastCartOpen={setFastCartOpen} />}
      <div id="pixelTagId"></div>
      <div id="pixelTagId2"></div>
      <div id="pixelTagId3"></div>
      <div id="pixelTagId4"></div>
      <div id="pixelTagId5"></div>
      <div id="pixelTagId6"></div>
      <div id="pixelTagId7"></div>
      <div id="pixelTagId8"></div>
    </>
  );
}

export default App;
