import { useEffect, useState } from "react";
import {
  getCart,
  getModelsByIdsArray,
  updateCart,
  getPromocode,
  sendCallback,
} from "../../services/axios";
import Header from "../../components/Header/Header";
import stars0 from "../../assets/images/red-stars.png";
import stars20 from "../../assets/images/red-stars20.png";
import stars40 from "../../assets/images/red-stars40.png";
import stars60 from "../../assets/images/red-stars60.png";
import stars80 from "../../assets/images/red-stars80.png";
import stars100 from "../../assets/images/red-stars100.png";
import { CartPlusIcon } from "../../assets/icons/CartPlusIcon.jsx";
import { CartMinusIcon } from "../../assets/icons/CartMinusIcon.jsx";
import { CartBinIcon } from "../../assets/icons/CartBinIcon.jsx";
import { PromocodeApplied } from "../../assets/icons/PromocodeApplied.jsx";
import NovaPoshtaInput from "../../components/Cart/NovaPoshtaInput/NovaPoshtaInput.jsx";
import { CheckoutPromocodePlusIcon } from "../../assets/icons/CheckoutPromocodePlusIcon.jsx";
import { CheckoutPromocodeCrossIcon } from "../../assets/icons/CheckoutPromocodeCrossIcon.jsx";
import { sendCallbackMonobank } from "../../services/monobank.js";
import modalSuccess from "../../assets/images/modalSuccess.webp";
import CheckoutCross from "../../assets/icons/CheckoutCross.jsx";
import { CheckoutBinIcon } from "../../assets/icons/CheckoutBinIcon.jsx";
import { useGTMEvents } from "../../services/google.js";
import { generateRandomNumber } from "../../utils/generateRandomNumber.js";

const Checkout = ({ setCartOpen }) => {
  const [items, setItems] = useState([]);
  const [update, setUpdate] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [total, setTotal] = useState(0);
  const [models, setModels] = useState([]);
  const [promocode, setPromocode] = useState("");
  const [promocodeActivated, setPromocodeActivated] = useState(
    localStorage.getItem("promocodeActivated")
      ? JSON.parse(localStorage.getItem("promocodeActivated"))
      : null
  );
  const [promocodeError, setPromocodeError] = useState(false);
  const [phone, setPhone] = useState("");
  const [bayer_name, setBayerName] = useState("");
  const [city, setCity] = useState("");
  const [branch, setBranch] = useState("");
  const [payment, setPayment] = useState("offline");
  const [promocodeOpened, setPromocodeOpened] = useState(false);
  const [orderedModalOpen, setOrderedModalOpen] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [branchError, setBranchError] = useState(false);
  const [selectedCity, setSelectedCity] = useState(false);

  const { beginCheckout, purchase, paymentSuccess, paymentFailure } =
    useGTMEvents();

  function isNumber(value) {
    return typeof value === "number" && !isNaN(value);
  }

  const onNameChange = (e) => {
    setBayerName(e.target.value);
  };

  const onPhoneChange = (e) => {
    if (e.target.value.trim() === "") {
      setPhone("380");
    } else if (phone === "38") {
      setPhone("380");
    } else if (phone === "3") {
      setPhone("380");
    } else {
      const newValue = +e.target.value.trim();
      if (isNumber(newValue)) {
        if (
          e.target.value.length > 0 &&
          isNumber(+e.target.value[e.target.value.length - 1])
        ) {
          setPhone(e.target.value.trim());
        }
      }
    }
  };

  useEffect(() => {
    let pixelId = localStorage.getItem("pixel");
    // Inject Facebook pixel script
    const el = document.getElementById("pixelTagId");
    if (el) {
      /* global fbq */ // Declare fbq as a global variable
      // eslint-disable-next-line
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", pixelId);
      fbq("track", "PageView");
      el.innerHTML = `<img alt='test' src=https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1 style=\"display:none\" width=\"1px\" height=\"1px\">`;
    }
  }, []);

  useEffect(() => {
    let pixelId = localStorage.getItem("pixel");
    // Inject Facebook pixel script
    const el = document.getElementById("pixelTagId5");
    if (el) {
      /* global fbq */ // Declare fbq as a global variable
      // eslint-disable-next-line
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", pixelId);
      fbq("track", "InitiateCheckout");
      el.innerHTML = `<img alt='test' src=https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1 style=\"display:none\" width=\"1px\" height=\"1px\">`;
    }
  }, []);

  useEffect(() => {
    const fetchCart = async () => {
      const data = await getCart();
      if (data) {
        let totalPrice = 0;
        let applyDiscount = false;
        let alreadyApplied = false;

        let singleQuantityItems = data?.cart.filter(
          (item) => item.quantity === 1
        );
        if (
          data?.cart.some((item) => item.quantity > 1) ||
          singleQuantityItems?.length > 1
        ) {
          applyDiscount = true;
        }

        let cheapestItem = applyDiscount
          ? data?.cart.reduce(
              (min, item) => (item.price < min.price ? item : min),
              data?.cart[0]
            )
          : null;

        data?.cart.forEach((item) => {
          let itemTotalPrice = item.price * item.quantity;

          if (
            applyDiscount &&
            !alreadyApplied &&
            item.id === cheapestItem?.id
          ) {
            itemTotalPrice =
              item.price * (item.quantity - 1) + item.price * 0.8;
            alreadyApplied = true;
          }

          totalPrice += itemTotalPrice;
        });

        setTotal(totalPrice.toFixed(0));

        setItems(data.cart);

        beginCheckout({
          id: generateRandomNumber(),
          value: totalPrice.toFixed(0),
          currency: "UAH",
          items: [
            {
              id: data.cart[0].id,
              name: data.cart[0].name,
              price: data.cart[0].price,
              quantity: data.cart[0].quantity,
            },
          ],
          payment_method: "credit_card",
        });
      }
    };

    fetchCart();
  }, []);

  useEffect(() => {
    const fetchCart = async () => {
      const data = await getCart();
      let totalPrice = 0;
      let applyDiscount = false;
      let alreadyApplied = false;

      let singleQuantityItems = data?.cart.filter(
        (item) => item.quantity === 1
      );
      if (
        data?.cart.some((item) => item.quantity > 1) ||
        singleQuantityItems.length > 1
      ) {
        applyDiscount = true;
      }

      let cheapestItem = applyDiscount
        ? data?.cart.reduce(
            (min, item) => (item.price < min.price ? item : min),
            data?.cart[0]
          )
        : null;

      data?.cart.forEach((item) => {
        let itemTotalPrice = item.price * item.quantity;

        if (applyDiscount && !alreadyApplied && item.id === cheapestItem?.id) {
          itemTotalPrice = item.price * (item.quantity - 1) + item.price * 0.8;
          alreadyApplied = true;
        }

        totalPrice += itemTotalPrice;
      });
      setTotal(totalPrice.toFixed(0));
      setItems(data.cart);
    };
    if (refresh) {
      fetchCart();
      setRefresh(false);
    }
    if (update) {
      updateCart(items);
      setUpdate(false);
    }
  }, [update, refresh]);

  const handleClick = (index, delta) => {
    setItems((prevItems) => {
      const el = prevItems
        .map((item, i) => {
          return i === index
            ? item.quantity + delta === 0
              ? {}
              : { ...item, quantity: item.quantity + delta }
            : item;
        })
        .filter((obj) => Object.keys(obj).length !== 0);
      let totalPrice = 0;
      let applyDiscount = false;
      let alreadyApplied = false;

      let singleQuantityItems = el.filter((item) => item.quantity === 1);
      if (
        el.some((item) => item.quantity > 1) ||
        singleQuantityItems.length > 1
      ) {
        applyDiscount = true;
      }

      let cheapestItem = applyDiscount
        ? el.reduce((min, item) => (item.price < min.price ? item : min), el[0])
        : null;

      el.forEach((item) => {
        let itemTotalPrice = item.price * item.quantity;

        if (applyDiscount && !alreadyApplied && item.id === cheapestItem?.id) {
          itemTotalPrice = item.price * (item.quantity - 1) + item.price * 0.8;
          alreadyApplied = true;
        }

        totalPrice += itemTotalPrice;
      });
      setTotal(totalPrice.toFixed(0));

      return el;
    });
    setUpdate(true);
  };

  useEffect(() => {
    let modelsIds = JSON.parse(localStorage.getItem("lastSeenModels")) || [];

    const fetchModels = async () => {
      setModels(await getModelsByIdsArray(modelsIds));
    };

    fetchModels();
  }, []);

  return (
    <>
      <Header setCartOpen={setCartOpen} />
      <section className="checkout">
        <div className="container checkout__wrapper">
          <div className="checkout__main">
            <p className="checkout__title">Оформити замовлення</p>
            {items.length > 0 && (
              <>
                <div>
                  <ul className="checkout__list">
                    {items.map(
                      (
                        {
                          id,
                          image,
                          name,
                          price,
                          color,
                          quantity,
                          description,
                          score,
                          reviewsNumber,
                        },
                        index
                      ) => {
                        let scoreImg = "";
                        if (score === "4" || score === "4,1") {
                          scoreImg = stars0;
                        } else if (score === "4,2" || score === "4,3") {
                          scoreImg = stars20;
                        } else if (score === "4,4" || score === "4,5") {
                          scoreImg = stars40;
                        } else if (score === "4,6" || score === "4,7") {
                          scoreImg = stars60;
                        } else if (score === "4,8" || score === "4,9") {
                          scoreImg = stars80;
                        } else if (score === "5") {
                          scoreImg = stars100;
                        }
                        let multiplier = 1;
                        let applyDiscount = false;

                        let singleQuantityItems = items?.filter(
                          (item) => item.quantity === 1
                        );
                        if (
                          items?.some((item) => item.quantity > 1) ||
                          singleQuantityItems?.length > 1
                        ) {
                          applyDiscount = true;
                        }

                        let cheapestItem = applyDiscount
                          ? items?.reduce(
                              (min, item) =>
                                item.price < min.price ? item : min,
                              items[0]
                            )
                          : null;
                        if (
                          applyDiscount &&
                          id === cheapestItem?.id &&
                          color === cheapestItem?.color
                        ) {
                          multiplier = 0.8;
                        }
                        return (
                          <li className="checkout__item" key={index}>
                            <CheckoutBinIcon
                              handleClick={handleClick}
                              index={index}
                              quantity={quantity}
                            />
                            <div className="cart__item--thumb">
                              <img
                                src={image}
                                alt="model"
                                className="cart__model--image"
                              />
                              <div className="checkout__model--info">
                                <p className="cart__model--name">
                                  {name} {color}
                                </p>
                                <div className="cart__start--wrapper">
                                  <img
                                    src={scoreImg}
                                    alt="score"
                                    className="checkout__stars"
                                  />
                                  {reviewsNumber && (
                                    <p className={"model__reviews--number"}>
                                      {`(${reviewsNumber}) відгуки`}
                                    </p>
                                  )}
                                </div>
                                <p className="cart__item--description">
                                  {description}
                                </p>
                              </div>
                            </div>
                            <div className="cart__thumb">
                              <div className="cart__quantity--wrapper">
                                <CartMinusIcon
                                  handleClick={handleClick}
                                  index={index}
                                />
                                <div className="cart__quantity--thumb">
                                  <p className="cart__model--quantity">
                                    {quantity}
                                  </p>
                                </div>
                                <CartPlusIcon
                                  handleClick={handleClick}
                                  index={index}
                                />
                              </div>
                              <div className="checkout__detalization--wrapper checkout__detalization--mobile">
                                <p className="cart__model--price checkout__model--price">
                                  {Math.ceil(
                                    (
                                      price * (quantity - 1) +
                                      price * multiplier
                                    ).toFixed(0)
                                  )}
                                  грн
                                </p>
                                {multiplier === 0.8 ? (
                                  <div className="checkout__detalization--wrapper">
                                    <p className="checkout__detalization--text">
                                      x{quantity - 1} {price}грн
                                    </p>
                                    <p className="checkout__detalization--text">
                                      x1 {(price * multiplier).toFixed(0)}грн
                                    </p>
                                  </div>
                                ) : (
                                  <div className="checkout__detalization--wrapper">
                                    <p className="checkout__detalization--text">
                                      x{quantity} {price}грн
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="cart__item--wrapper">
                              <CartBinIcon
                                handleClick={handleClick}
                                index={index}
                                quantity={quantity}
                              />
                              <div>
                                <p className="cart__model--price">
                                  {Math.ceil(
                                    (
                                      price * (quantity - 1) +
                                      price * multiplier
                                    ).toFixed(0)
                                  )}
                                  грн
                                </p>
                                {multiplier === 0.8 ? (
                                  <div className="checkout__detalization--wrapper">
                                    <p className="checkout__detalization--text">
                                      x{quantity - 1} {price}грн
                                    </p>
                                    <p className="checkout__detalization--text">
                                      x1 {(price * multiplier).toFixed(0)}грн
                                    </p>
                                  </div>
                                ) : (
                                  <div className="checkout__detalization--wrapper">
                                    <p className="checkout__detalization--text">
                                      x{quantity} {price}грн
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                  <div className="checkout__receiver--wrapper">
                    <p className="checkout__subtitle">Отримувач</p>
                    <div className="checkout__name--wrapper">
                      <div className="checkout__name--thumb">
                        <p className="checkout__name">ПІБ</p>
                        <input
                          type="text"
                          placeholder="Ваше ПІБ"
                          className={
                            nameError
                              ? "checkout__input--name checkout__input--error"
                              : "checkout__input--name"
                          }
                          value={bayer_name}
                          onChange={onNameChange}
                        />
                      </div>
                      <div className="checkout__name--thumb">
                        <p className="checkout__name">Номер телефону</p>
                        <input
                          type="text"
                          placeholder="+38 (095) 555 - 55 - 55"
                          className={
                            phoneError
                              ? "checkout__input--name checkout__input--error"
                              : "checkout__input--name"
                          }
                          onClick={() => {
                            if (phone.trim() === "") {
                              setPhone("380");
                            } else if (phone === "38") {
                              setPhone("380");
                            } else if (phone === "3") {
                              setPhone("380");
                            }
                          }}
                          maxLength={13}
                          minLength={12}
                          value={phone}
                          onChange={onPhoneChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="novaPoshta__wrappper">
                    <p className="checkout__subtitle">Доставка</p>
                    <NovaPoshtaInput
                      city={city}
                      setCity={setCity}
                      branch={branch}
                      setBranch={setBranch}
                      cityError={cityError}
                      branchError={branchError}
                      setCityError={setCityError}
                      setBranchError={setBranchError}
                      setSelectedCity={setSelectedCity}
                    />
                  </div>
                  <div>
                    <p className="checkout__subtitle">Оплата</p>
                    <div className="checkout__payment--thumb">
                      <div
                        className="checkout__payment--wrapper"
                        onClick={() => {
                          setPayment("offline");
                        }}
                      >
                        <div className="novaPoshta__method--choose">
                          {payment === "offline" && (
                            <div className="novaPoshta__method--choosen"></div>
                          )}
                        </div>
                        <p className="novaPoshta__option">
                          Оплата при отриманні (Накладений платіж)
                        </p>
                      </div>
                      <div
                        className="checkout__payment--wrapper checkout__online"
                        onClick={() => {
                          setPayment("online");
                        }}
                      >
                        <div className="checkout__online--thumb">
                          <div className="novaPoshta__method--choose">
                            {payment === "online" && (
                              <div className="novaPoshta__method--choosen"></div>
                            )}
                          </div>
                          <p className="novaPoshta__option">Оплата онлайн</p>
                        </div>
                        <div className="checkout__online--wrapper">
                          <p className="checkout__online--text">
                            Безкоштовна доставка
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="checkout__thumb">
            <div className="checkout__promocode--wrapper">
              {promocodeActivated ? (
                <div className="checkout__promocode--applied">
                  <p className="checkout__promocode--text">
                    Промокод активний!
                  </p>
                  <PromocodeApplied />
                </div>
              ) : (
                <>
                  <div className="checkout__promocode--top">
                    <p className="checkout__promocode--text">Промокод</p>
                    {promocodeOpened ? (
                      <button
                        type="button"
                        className="checkout__promocode--open"
                        onClick={() => {
                          setPromocodeOpened(false);
                        }}
                      >
                        Закрити <CheckoutPromocodeCrossIcon />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="checkout__promocode--open"
                        onClick={() => {
                          setPromocodeOpened(true);
                        }}
                      >
                        Застосувати <CheckoutPromocodePlusIcon />
                      </button>
                    )}
                  </div>
                  {promocodeOpened && (
                    <div className="checkout__promocode--opened">
                      <input
                        type="text"
                        placeholder="Введіть промокод"
                        className={
                          promocodeError
                            ? "checkout__promocode--input checkout__input--error"
                            : "checkout__promocode--input"
                        }
                        value={promocode}
                        onChange={(e) => {
                          setPromocode(e.target.value);
                        }}
                      />
                      <button
                        type="button"
                        className="checkout__promocode--button"
                        onClick={async () => {
                          const result = await getPromocode(promocode);
                          if (result) {
                            localStorage.setItem(
                              "promocodeActivated",
                              JSON.stringify(result)
                            );
                            setPromocodeActivated(result);
                          } else {
                            setPromocodeError(true);
                          }
                        }}
                      >
                        Активувати
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="checkout__data">
              <p className="checkout__data--title">Разом</p>
              <div className="checkout__delivery">
                <p className="checkout__delivery--title">Вартість доставки</p>
                <p className="checkout__delivery--value">
                  {promocodeActivated?.type === "delivery"
                    ? "Безкоштовно"
                    : "За тарифами перевізника"}
                </p>
              </div>
              <div className="checkout__data--line"></div>
              <div className="checkout__delivery">
                <p className="checkout__delivery--title">До сплати</p>
                <p className="checkout__total">
                  {promocodeActivated
                    ? promocodeActivated?.type === "discount"
                      ? Math.ceil(
                          total * (1 - promocodeActivated?.discount / 100)
                        )
                      : promocodeActivated?.type === "welcome"
                      ? Math.ceil(total - promocodeActivated?.discount)
                      : total
                    : total}
                  грн
                </p>
              </div>
              <div className="checkout__data--line"></div>
              <button
                type="button"
                className="checkout__apply--button"
                onClick={async () => {
                  if (!bayer_name) {
                    setNameError(true);
                  } else {
                    setNameError(false);
                  }

                  if (!phone || phone.length < 12) {
                    setPhoneError(true);
                  } else {
                    setPhoneError(false);
                  }

                  if (!city || !selectedCity) {
                    setCityError(true);
                  } else {
                    setCityError(false);
                  }

                  if (!branch) {
                    setBranchError(true);
                  } else {
                    setBranchError(false);
                  }

                  if (
                    city &&
                    selectedCity &&
                    branch &&
                    phone &&
                    bayer_name &&
                    phone.length > 11
                  ) {
                    const ids = [];
                    const quantities = [];
                    const prices = [];
                    const colors = [];
                    const names = [];
                    const discounts = [];
                    let comment = "";

                    for (let i = 0; i < items.length; i += 1) {
                      let multiplier = 1;
                      let applyDiscount = false;

                      let singleQuantityItems = items?.filter(
                        (item) => item.quantity === 1
                      );
                      if (
                        items?.some((item) => item.quantity > 1) ||
                        singleQuantityItems?.length > 1
                      ) {
                        applyDiscount = true;
                      }

                      let cheapestItem = applyDiscount
                        ? items?.reduce(
                            (min, item) =>
                              item.price < min.price ? item : min,
                            items[0]
                          )
                        : null;
                      if (
                        applyDiscount &&
                        items[i].id === cheapestItem?.id &&
                        items[i].color === cheapestItem?.color
                      ) {
                        multiplier = 0.2;
                      }
                      ids.push(items[i].id);
                      quantities.push(items[i].quantity);
                      prices.push(items[i].price);
                      discounts.push(
                        multiplier === 0.2
                          ? (
                              (items[i].price * multiplier) /
                              items[i].quantity
                            ).toFixed(0)
                          : 0
                      );
                      colors.push(items[i].color);
                      names.push(items[i].name);
                      comment += items[i].name;
                      comment += ` ${items[i].color} `;
                    }

                    const amount = `${
                      promocodeActivated?.type === "discount"
                        ? Math.ceil(
                            total * (1 - promocodeActivated?.discount / 100)
                          )
                        : promocodeActivated?.type === "welcome"
                        ? Math.ceil(total - promocodeActivated?.discount)
                        : total
                    }`;

                    if (amount > 2499) {
                      comment += " - безкоштовна доставка";
                    }
                    if (payment === "offline") {
                      await sendCallback(
                        prices,
                        phone,
                        bayer_name,
                        promocodeActivated
                          ? promocodeActivated?.type === "discount" ||
                            promocodeActivated?.type === "welcome"
                            ? `${comment} - промокод на знижку ${
                                promocodeActivated?.type === "welcome"
                                  ? promocodeActivated?.discount
                                  : promocodeActivated?.discount + "%"
                              }  - загальна вартість ${amount}`
                            : promocodeActivated?.type === "delivery"
                            ? `${comment} - промокод на безкоштовну доставку - загальна вартість ${amount}`
                            : `${comment} - промокод на подарунок - загальна вартість ${amount}`
                          : `${comment} - загальна вартість ${amount}`,
                        `${city} - ${branch}`,
                        ids,
                        quantities,
                        colors,
                        names,
                        discounts
                      );
                      localStorage.removeItem("promocodeActivated");
                      localStorage.removeItem("userCartId");
                      setOrderedModalOpen(true);

                      let pixelId = localStorage.getItem("pixel");
                      // Inject Facebook pixel script
                      const el = document.getElementById("pixelTagId7");
                      if (el) {
                        /* global fbq */ // Declare fbq as a global variable
                        // eslint-disable-next-line
                        !(function (f, b, e, v, n, t, s) {
                          if (f.fbq) return;
                          n = f.fbq = function () {
                            n.callMethod
                              ? n.callMethod.apply(n, arguments)
                              : n.queue.push(arguments);
                          };
                          if (!f._fbq) f._fbq = n;
                          n.push = n;
                          n.loaded = !0;
                          n.version = "2.0";
                          n.queue = [];
                          t = b.createElement(e);
                          t.async = !0;
                          t.src = v;
                          s = b.getElementsByTagName(e)[0];
                          s.parentNode.insertBefore(t, s);
                        })(
                          window,
                          document,
                          "script",
                          "https://connect.facebook.net/en_US/fbevents.js"
                        );
                        fbq("init", pixelId);
                        fbq("track", "Lead");
                        el.innerHTML = `<img alt='test' src=https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1 style=\"display:none\" width=\"1px\" height=\"1px\">`;
                      }
                    } else {
                      const data = await sendCallbackMonobank(
                        amount,
                        prices,
                        phone,
                        bayer_name,
                        promocodeActivated
                          ? promocodeActivated?.type === "discount" ||
                            promocodeActivated?.type === "welcome"
                            ? `${comment} - промокод на знижку ${
                                promocodeActivated?.type === "welcome"
                                  ? promocodeActivated?.discount
                                  : promocodeActivated?.discount + "%"
                              }  - загальна вартість ${amount}`
                            : promocodeActivated?.type === "delivery"
                            ? `${comment} - промокод на безкоштовну доставку - загальна вартість ${amount}`
                            : `${comment} - промокод на подарунок - загальна вартість ${amount}`
                          : `${comment} - загальна вартість ${amount}`,
                        `${city} - ${branch}`,
                        ids,
                        quantities,
                        colors,
                        names,
                        discounts
                      );
                      let pixelId = localStorage.getItem("pixel");
                      // Inject Facebook pixel script
                      const el = document.getElementById("pixelTagId6");
                      if (el) {
                        /* global fbq */ // Declare fbq as a global variable
                        // eslint-disable-next-line
                        !(function (f, b, e, v, n, t, s) {
                          if (f.fbq) return;
                          n = f.fbq = function () {
                            n.callMethod
                              ? n.callMethod.apply(n, arguments)
                              : n.queue.push(arguments);
                          };
                          if (!f._fbq) f._fbq = n;
                          n.push = n;
                          n.loaded = !0;
                          n.version = "2.0";
                          n.queue = [];
                          t = b.createElement(e);
                          t.async = !0;
                          t.src = v;
                          s = b.getElementsByTagName(e)[0];
                          s.parentNode.insertBefore(t, s);
                        })(
                          window,
                          document,
                          "script",
                          "https://connect.facebook.net/en_US/fbevents.js"
                        );
                        fbq("init", pixelId);
                        fbq("track", "Purchase");
                        el.innerHTML = `<img alt='test' src=https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1 style=\"display:none\" width=\"1px\" height=\"1px\">`;
                      }
                      localStorage.removeItem("promocodeActivated");
                      localStorage.removeItem("userCartId");
                      window.location.href = data.invoiceLink;
                    }

                    purchase({
                      id: generateRandomNumber(),
                      value: parseFloat(amount),
                      currency: "UAH",
                      items: [
                        {
                          id: items[0].id,
                          name: items[0].name,
                          price: items[0].price,
                          quantity: items[0].quantity,
                        },
                      ],
                      payment_method: "credit_card",
                    });
                  }
                }}
              >
                Оформити замовлення
              </button>
            </div>
          </div>
        </div>
        {orderedModalOpen && (
          <div className="checkout__backdrop">
            <div className="checkout__modal">
              <div className="checkout__modal--wrapper">
                <p className="checkout__modal--title">Замовлення прийняте</p>
                <CheckoutCross setOrderedModalOpen={setOrderedModalOpen} />
              </div>
              <div className="checkout__modal--thumb">
                <img
                  src={modalSuccess}
                  alt="Замовлення успішне"
                  className="checkout__modal--image"
                />
                <p className="checkout__modal--text">Успішно!</p>
                <p className="checkout__modal--description">
                  Ваше замовлення взято на обробку, ми сповістимо вас коли
                  замовлення буде відправлено.
                </p>
              </div>
              <button
                type="button"
                className="checkout__modal--button"
                onClick={() => {
                  setOrderedModalOpen(false);
                  window.location.href = "/";
                }}
              >
                Зрозуміло
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Checkout;
