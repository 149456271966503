import axios from "axios";
import { serialize } from "php-serialize";
import { generateRandomNumber } from "../utils/generateRandomNumber";

export const sendCallbackMonobank = async (
  amount,
  prices,
  phone,
  bayer_name,
  comment,
  delivery_adress,
  ids,
  quantities,
  colors,
  names,
  discounts
) => {
  if (!bayer_name) {
    return;
  }

  if (!phone) {
    return;
  }

  const products = [];

  for (let i = 0; i < ids.length; i += 1) {
    products.push({
      sku: ids[i],
      price: prices[i],
      quantity: quantities[i],
      name: names[i],
      discount_amount: discounts[i],
      properties: [
        {
          name: "Color",
          value: colors[i],
        },
      ],
    });
  }

  // const serialized = serialize(products);
  // const encoded = encodeURIComponent(serialized);

  const utm_source = localStorage.getItem("utm_source") ?? "";
  const utm_medium = localStorage.getItem("utm_medium") ?? "";
  const utm_term = localStorage.getItem("utm_term") ?? "";
  const utm_content = localStorage.getItem("utm_content") ?? "";
  const utm_campaign = localStorage.getItem("utm_campaign") ?? "";

  try {
    const { data } = await axios.post(
      `https://princesss.store/api/monobank/createInvoice`,
      {
        amount,
        order_id: generateRandomNumber(),
        // country: "UA",
        promocode: localStorage.getItem("promocodeActivated")
          ? JSON.parse(localStorage.getItem("promocodeActivated")).promocode
          : null,
        discount_percent: localStorage.getItem("promocodeActivated")
          ? JSON.parse(localStorage.getItem("promocodeActivated")).type ===
            "discount"
            ? JSON.parse(localStorage.getItem("promocodeActivated")).discount
            : 0
          : 0,
        discount_amount: localStorage.getItem("promocodeActivated")
          ? JSON.parse(localStorage.getItem("promocodeActivated")).type ===
            "welcome"
            ? JSON.parse(localStorage.getItem("promocodeActivated")).discount
            : 0
          : 0,
        phone,
        products,
        bayer_name,
        // site: window.location.hostname,
        comment,
        delivery_adress,
        utm_source,
        utm_medium,
        utm_term,
        utm_content,
        utm_campaign,
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
