import { useEffect, useState } from "react";
import img from "../../assets/images/promocode-popup-img.webp";
import CrossIcon from "../../assets/icons/CrossIcon.jsx";
import { LogoBlack } from "../../assets/icons/LogoBlack.jsx";
import SuccessIcon from "../../assets/icons/SuccessIcon.jsx";
import { sendSms } from "../../services/axios.js";
import { useGTMEvents } from "../../services/google.js";

export const PromocodePopup = ({ setIsOpen }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const { discountPopup } = useGTMEvents();

  useEffect(() => {
    document.body.classList.add("block__scroll");
    sessionStorage.setItem("wasOpenPromocodePopup", "true");
    return () => {
      document.body.classList.remove("block__scroll");
    };
  }, []);

  const submitButtonClick = () => {
    if (phoneNumber.trim().length >= 12) {
      sendSms(
        phoneNumber,
        "2212 - промокод , активний протягом 30 хвилин https://bit.ly/4fvr5os"
      );
      discountPopup({
        value: 100,
        popup_type: "welcome_discount",
        interaction: "clicked",
      });
      setIsSuccess(true);
      localStorage.setItem("receivedPromocodes", "true");
    }
  };

  function isNumber(value) {
    return typeof value === "number" && !isNaN(value);
  }

  const onPhoneChange = (e) => {
    if (e.target.value.trim() === "") {
      setPhoneNumber("380");
    } else if (phoneNumber === "38") {
      setPhoneNumber("380");
    } else if (phoneNumber === "3") {
      setPhoneNumber("380");
    } else {
      const newValue = +e.target.value.trim();
      if (isNumber(newValue)) {
        if (
          e.target.value.length > 0 &&
          isNumber(+e.target.value[e.target.value.length - 1])
        ) {
          setPhoneNumber(e.target.value.trim());
        }
      }
    }
  };

  return (
    <div className={"promocode__backdrop"}>
      <div className={"promocode__popup"}>
        <img
          src={img}
          alt="promocode popup"
          className={"promocode__popup--image"}
        />
        <div className={"promocode__popup--content"}>
          <div
            className={"promocode__popup--close"}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <CrossIcon />
          </div>

          {!isSuccess ? (
            <>
              <div className={"promocode__popup--logo"}>
                <LogoBlack />
              </div>
              <div className={"promocode__popup--info"}>
                <span className={"promocode-popup--text"}>Отримайте</span>
                <span className={"promocode__popup--title"}>-100 гривень</span>
                <p className={"promocode__popup--paragraph"}>
                  на перше замовлення (Для використання введіть промокод з смс у
                  полі "промокод")
                </p>
                <label
                  className={"reviews__input--wrapper promocode__popup--input"}
                >
                  <span className={"reviews__label"}>Номер телефону</span>
                  <input
                    type="text"
                    placeholder="38(___) ___-__-__"
                    maxLength={13}
                    minLength={12}
                    className={"reviews__input"}
                    onChange={onPhoneChange}
                    onClick={() => {
                      if (phoneNumber === "") {
                        setPhoneNumber("380");
                      }
                    }}
                    value={phoneNumber}
                  />
                </label>
                <button className={"button"} onClick={submitButtonClick}>
                  <span className={"button__text"}>Отримати</span>
                </button>
              </div>
            </>
          ) : (
            <div className={"promocode__popup--success"}>
              <div className="reviews__success--wrapper promocode__success--content">
                <div className="reviews__success--image">
                  <SuccessIcon />
                </div>
                <div>
                  <span className="reviews__success-title">Успішно!</span>
                  <p className="reviews__success--text">
                    Ми надіслали вам на номер телефону повідомлення
                  </p>
                </div>
              </div>
              <button className={"button"} onClick={() => setIsOpen(false)}>
                <span className={"button__text"}>Закрити</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
