import { Link } from "react-scroll";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import CallbackIcon from "../../assets/icons/CallbackIcon";
import LogoIcon from "../../assets/icons/LogoIcon";
import BasketIcon from "../../assets/icons/BasketIcon";
import SearchIcon from "../../assets/icons/SearchIcon";
import BurgerIcon from "../../assets/icons/BurgerIcon";
import SalesIcon from "../../assets/icons/SalesIcon";
import SideBar from "../Header/SideBar";
import CloseIcon from "../../assets/icons/CloseIcon";
import MobileSideBar from "./MobileSideBar";
import { Search } from "../Search/Search.jsx";
import { getCart } from "../../services/axios.js";

const Header = ({
  setIsHidden,
  isHidden,
  setFrom,
  from,
  modelName,
  isMenuOpen,
  setIsMenuOpen,
  wheelland,
  setCartOpen,
}) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(0);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const ferchCart = async () => {
      const data = await getCart();

      if (data) {
        if (data?.cart?.length > 0) {
          let total = 0;
          for (const el of data.cart) {
            total += el.quantity;
          }

          setCartQuantity(total);
        }
      }
    };

    ferchCart();
  }, []);

  const [isOpenSearch, setIsOpenSearch] = useState(false);

  return (
    <>
      <header className="header header__desktop">
        <div className="header__container">
          <div className="header__wrapper header__wrapper--first">
            <nav className="nav">
              <ul className="nav__list">
                {/* <NavLink to="/" className="nav__link">
                  <li className="nav__item nav__item--sales">
                    <SalesIcon />
                    Акції
                  </li>
                </NavLink> */}
                {/* <NavLink to="/" className="nav__link">
                  <li className="nav__item">Про нас</li>
                </NavLink> */}
                {/* <NavLink to="/" className="nav__link">
                  <li className="nav__item">Відгуки</li>
                </NavLink> */}
                <NavLink to="/delivery" className="nav__link">
                  <li className="nav__item">Доставка і оплата</li>
                </NavLink>
                <NavLink to="/exchange" className="nav__link">
                  <li className="nav__item">Обмін і повернення</li>
                </NavLink>
                {/* <NavLink to="/" className="nav__link">
                  <li className="nav__item">Контакти</li>
                </NavLink> */}
              </ul>
            </nav>
            <a
              className="header__callback--btn"
              href="https://ig.me/m/princess.wigs"
              target="_blank"
              rel="noreferrer"
            >
              {/* <CallbackIcon /> */}
              Зворотній зв'язок
            </a>
          </div>
          <div className="header__wrapper header__wrapper--second">
            <div className="header__btn--wrapper">
              <button
                className="header__btn header__catalog--btn"
                onClick={toggleSidebar} // Додано onClick
              >
                {isSidebarOpen ? <CloseIcon /> : <BurgerIcon />}
                Каталог товарів
              </button>
              <button
                className="header__btn header__search--btn"
                onClick={() => setIsOpenSearch(true)}
              >
                <SearchIcon />
                Пошук товарів
              </button>
            </div>
            {isHomePage ? (
              <Link
                to="/"
                spy={true}
                smooth={true}
                duration={500}
                className="header__link header__logo--link"
              >
                <LogoIcon />
              </Link>
            ) : (
              <NavLink to="/" className="header__link header__logo--link">
                <LogoIcon />
              </NavLink>
            )}
            <div className="header__btn--wrapper header__btn--wrapper--second">
              <button
                className="header__btn header__basket--btn"
                onClick={() => {
                  setCartOpen(true);
                }}
              >
                <BasketIcon />
                Кошик
                {cartQuantity > 0 && (
                  <div className="header__cart--exist">
                    <p className="header__cart--text">{cartQuantity}</p>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </header>
      <header className="header header__mobile">
        <div className="header__wrapper--mobile">
          <button
            className="header__catalog--btn--mobile"
            onClick={toggleSidebar}
          >
            <BurgerIcon />
          </button>
          {isHomePage ? (
            <Link
              to="/"
              spy={true}
              smooth={true}
              duration={500}
              className="header__link--mobile header__logo--link--mobile"
            >
              <LogoIcon />
            </Link>
          ) : (
            <NavLink
              to="/"
              className="header__link--mobile header__logo--link--mobile"
            >
              <LogoIcon />
            </NavLink>
          )}
          <button
            className="header__btn--mobile header__search--btn--mobile"
            onClick={() => setIsOpenSearch(true)}
          >
            <SearchIcon />
          </button>
          <button
            className="header__btn--mobile header__basket--btn--mobile"
            onClick={() => {
              setCartOpen(true);
            }}
          >
            {cartQuantity > 0 && (
              <div className="header__cart--exist">
                <p className="header__cart--text">{cartQuantity}</p>
              </div>
            )}
            <BasketIcon />
          </button>
        </div>
      </header>
      {isSidebarOpen && (
        <>
          <div className="sidebar__container--desktop">
            <SideBar isOpen={isSidebarOpen} onClose={toggleSidebar} />
          </div>
          <div className="sidebar__container--mobile">
            <MobileSideBar isOpen={isSidebarOpen} onClose={toggleSidebar} />
          </div>
        </>
      )}

      {isOpenSearch && (
        <Search setIsOpen={setIsOpenSearch} isOpenSearch={isOpenSearch} />
      )}
    </>
  );
};

export default Header;
