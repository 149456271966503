import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getFilteredModels } from "../../services/axios.js";
import { LastSeenItem } from "../LastSeenItem/LastSeenItem.jsx";
import CrossIcon from "../../assets/icons/CrossIcon.jsx";
import SearchIcon from "../../assets/icons/SearchIcon.jsx";
import ArrowRight from "../../assets/icons/ArrowRight.jsx";

export const Search = ({ setIsOpen, isOpenSearch }) => {
  const [searchText, setSearchText] = useState("");
  const [typingTimer, setTypingTimer] = useState(null);
  const [models, setModels] = useState(null);

  useEffect(() => {
    document.body.classList.add("block__scroll");
    return () => {
      document.body.classList.remove("block__scroll");
    };
  }, []);

  const searchOverlayClick = () => {
    setIsOpen(false);
  };
  const searchContentClick = (event) => {
    event.stopPropagation();
  };

  const searchRef = useRef(null);

  useEffect(() => {
    if (isOpenSearch && searchRef.current) {
      searchRef.current.focus();
    }
  }, [isOpenSearch]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
    if (typingTimer) {
      clearTimeout(typingTimer);
    }
    setTypingTimer(
      setTimeout(() => {
        value.trim() !== "" && getModels(value);
      }, 2000)
    );
  };

  const getModels = async (name) => {
    const result = await getFilteredModels(true, { nameStart: name });
    const groupedResult = result?.data.reduce((acc, item) => {
      let group = acc.find((g) => g.filtername === item.filterTypes);
      if (!group) {
        group = { filtername: item.filterTypes, models: [] };
        acc.push(group);
      }
      group.models.push(item);
      return acc;
    }, []);
    setModels(groupedResult);
  };

  return (
    <div className={"search"} onClick={searchOverlayClick}>
      <div className={"search__heading"} onClick={searchContentClick}>
        <div className={"container search__container"}>
          <div className={"search__heading--wrapper"}>
            <label className={"search__heading--left"}>
              <SearchIcon />
              <input
                type="text"
                ref={searchRef}
                className={"search__input"}
                value={searchText}
                onChange={handleInputChange}
              />
            </label>
            <div
              className={"search__heading--right"}
              onClick={() => setIsOpen(false)}
            >
              <CrossIcon />
            </div>
          </div>
        </div>
      </div>
      {models && (
        <div className={"search__body"}>
          <div className={"container search__container"}>
            {models.length > 0 ? (
              models.map((el, index) => (
                <div key={index} className={"search__group"}>
                  <Link
                    to={`/catalog?type=${el.filtername}`}
                    className={"search__title"}
                  >
                    <span>{el.filtername}</span>
                    <ArrowRight />
                  </Link>
                  <div className={"line__divider"}></div>
                  <div className={"search__items--wrapper"}>
                    {el.models.map((model) => (
                      <Link to={`/modeli/${model.path}`} key={model.id}>
                        <LastSeenItem model={model} />
                      </Link>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <span className={"search__message"}>Нічого не знайдено.</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
