const SuccessIcon = () => {
    return (
        <svg width="124" height="114" viewBox="0 0 124 114" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M78.7006 2.49619C78.7006 2.49619 57.6093 -4.05629 38.4428 8.70466C19.2762 21.4683 11.0164 45.4878 3.81148 64.7529C-3.39074 84.0179 3.16454 103.003 19.2252 111.083C35.2833 119.162 44.692 105.791 66.186 104.21C87.6799 102.63 93.7251 105.524 110.605 92.2798C127.484 79.035 128.356 49.4763 112.51 28.3352C96.6645 7.1942 78.7033 2.49619 78.7033 2.49619H78.7006Z"
                fill="#FCB25F"/>
            <path opacity="0.7"
                  d="M78.7006 2.49619C78.7006 2.49619 57.6093 -4.05629 38.4428 8.70466C19.2762 21.4683 11.0164 45.4878 3.81148 64.7529C-3.39074 84.0179 3.16454 103.003 19.2252 111.083C35.2833 119.162 44.692 105.791 66.186 104.21C87.6799 102.63 93.7251 105.524 110.605 92.2798C127.484 79.035 128.356 49.4763 112.51 28.3352C96.6645 7.1942 78.7033 2.49619 78.7033 2.49619H78.7006Z"
                  fill="white"/>
            <path
                d="M92 54.24V57C91.9963 63.4692 89.9015 69.764 86.028 74.9454C82.1545 80.1269 76.7099 83.9174 70.5061 85.7517C64.3023 87.5859 57.6718 87.3657 51.6034 85.1237C45.535 82.8818 40.354 78.7383 36.8329 73.3112C33.3118 67.8841 31.6394 61.4642 32.065 55.0089C32.4907 48.5537 34.9916 42.4089 39.1948 37.4912C43.3981 32.5734 49.0784 29.1461 55.3886 27.7204C61.6988 26.2947 68.3008 26.947 74.21 29.58M92 33L62 63.03L53 54.03"
                stroke="#22252B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default SuccessIcon