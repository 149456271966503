export const CartMinusIcon = ({ handleClick, index }) => {
  return (
    <svg
      onClick={() => {
        handleClick(index, -1);
      }}
      className="cart__model--symbol checkout__minus"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12.4006C21 12.5995 20.921 12.7903 20.7803 12.931C20.6397 13.0716 20.4489 13.1506 20.25 13.1506H3.75C3.55109 13.1506 3.36032 13.0716 3.21967 12.931C3.07902 12.7903 3 12.5995 3 12.4006C3 12.2017 3.07902 12.011 3.21967 11.8703C3.36032 11.7297 3.55109 11.6506 3.75 11.6506H20.25C20.4489 11.6506 20.6397 11.7297 20.7803 11.8703C20.921 12.011 21 12.2017 21 12.4006Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};
