import { useEffect, useState } from "react";
import CartCross from "../../../assets/icons/CartCross.jsx";
import stars0 from "../../../assets/images/red-stars.png";
import stars20 from "../../../assets/images/red-stars20.png";
import stars40 from "../../../assets/images/red-stars40.png";
import stars60 from "../../../assets/images/red-stars60.png";
import stars80 from "../../../assets/images/red-stars80.png";
import stars100 from "../../../assets/images/red-stars100.png";
import { CartPlusIcon } from "../../../assets/icons/CartPlusIcon.jsx";
import { CartMinusIcon } from "../../../assets/icons/CartMinusIcon.jsx";
import { sendFastCallback } from "../../../services/axios.js";
import success from "../../../assets/images/modalSuccess.webp";
import { useGTMEvents } from "../../../services/google.js";
import { generateRandomNumber } from "../../../utils/generateRandomNumber.js";

export const OneClickCart = ({ setFastCartOpen }) => {
  const [item, setItem] = useState(null);
  const [update, setUpdate] = useState(false);
  const [phone, setPhone] = useState("");
  const [bayer_name, setBayerName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [sent, setSent] = useState(false);

  const { purchase } = useGTMEvents();

  useEffect(() => {
    if (localStorage.getItem("userOneClickCart")) {
      setItem(JSON.parse(localStorage.getItem("userOneClickCart")));
    }
  }, [update]);

  const handleClick = (index, delta) => {
    setItem((prev) => {
      const obj = { ...prev };
      obj.quantity += delta;
      return obj;
    });
    localStorage.setItem(
      "userOneClickCart",
      JSON.stringify({ ...item, quantity: item.quantity + delta })
    );
    setUpdate((prev) => !prev);
  };

  function isNumber(value) {
    return typeof value === "number" && !isNaN(value);
  }

  const onNameChange = (e) => {
    setBayerName(e.target.value);
  };

  const onPhoneChange = (e) => {
    if (e.target.value.trim() === "") {
      setPhone("380");
    } else if (phone === "38") {
      setPhone("380");
    } else if (phone === "3") {
      setPhone("380");
    } else {
      const newValue = +e.target.value.trim();
      if (isNumber(newValue)) {
        if (
          e.target.value.length > 0 &&
          isNumber(+e.target.value[e.target.value.length - 1])
        ) {
          setPhone(e.target.value.trim());
        }
      }
    }
  };

  return (
    <div
      className="present__backdrop"
      id="backdrop"
      onClick={(e) => {
        if (e.target.id === "backdrop") {
          setFastCartOpen(false);
        }
      }}
    >
      <div className="oneClick__modal">
        <div className="cart__title--wrapper oneClick__title--wrapper">
          <p className="cart__title">Купити в 1 клік</p>
          <CartCross setCartOpen={setFastCartOpen} />
        </div>
        {item && !sent ? (
          <div className="oneClick__wrapper">
            <div className="checkout__item">
              <div className="cart__item--thumb">
                <img
                  src={item?.image}
                  alt="model"
                  className="cart__model--image"
                />
                <div className="checkout__model--info">
                  <p className="cart__model--name">
                    {item?.name} {item?.color}
                  </p>
                  <div className="cart__start--wrapper">
                    <img
                      src={
                        item.score === "4" || item.score === "4,1"
                          ? stars0
                          : item.score === "4,2" || item.score === "4,3"
                          ? stars20
                          : item.score === "4,4" || item.score === "4,5"
                          ? stars40
                          : item.score === "4,6" || item.score === "4,7"
                          ? stars60
                          : item.score === "4,8" || item.score === "4,9"
                          ? stars80
                          : item.score === "5"
                          ? stars100
                          : stars0
                      }
                      alt="score"
                      className="checkout__stars"
                    />
                    {item?.reviewsNumber && (
                      <p className={"model__reviews--number"}>
                        {`(${item?.reviewsNumber}) відгуки`}
                      </p>
                    )}
                  </div>
                  <p className="cart__item--description">{item?.description}</p>
                </div>
              </div>
              <div className="cart__thumb">
                <div className="cart__quantity--wrapper">
                  <CartMinusIcon handleClick={handleClick} index={0} />
                  <div className="cart__quantity--thumb">
                    <p className="cart__model--quantity">{item?.quantity}</p>
                  </div>
                  <CartPlusIcon handleClick={handleClick} index={0} />
                </div>
                <div className="checkout__detalization--wrapper checkout__detalization--mobile">
                  <p className="cart__model--price checkout__model--price">
                    {(item.quantity > 1
                      ? item.price * 0.8 + (item.quantity - 1) * item.price
                      : item.price
                    ).toFixed(0)}
                    грн
                  </p>
                  {item.quantity > 1 ? (
                    <div className="checkout__detalization--wrapper">
                      <p className="checkout__detalization--text">
                        x{item?.quantity - 1} {item?.price}грн
                      </p>
                      <p className="checkout__detalization--text">
                        x1 {(item?.price * 0.8).toFixed(0)}грн
                      </p>
                    </div>
                  ) : (
                    <div className="checkout__detalization--wrapper">
                      <p className="checkout__detalization--text">
                        x{item?.quantity} {item?.price}грн
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="cart__item--wrapper cart__oneClick--wrapper">
                <div>
                  <p className="cart__model--price">
                    {(item.quantity > 1
                      ? item.price * 0.8 + (item.quantity - 1) * item.price
                      : item.price
                    ).toFixed(0)}
                    грн
                  </p>
                  {item.quantity > 1 ? (
                    <div className="checkout__detalization--wrapper">
                      <p className="checkout__detalization--text">
                        x{item?.quantity - 1} {item?.price}грн
                      </p>
                      <p className="checkout__detalization--text">
                        x1 {(item?.price * 0.8).toFixed(0)}грн
                      </p>
                    </div>
                  ) : (
                    <div className="checkout__detalization--wrapper">
                      <p className="checkout__detalization--text">
                        x{item?.quantity} {item?.price}грн
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="oneClick__form">
              <div className="oneClick__input--wrapper">
                <div className="oneClick__input--thumb">
                  <p className="oneClick__input--text">Ім’я</p>
                  <input
                    type="text"
                    className={
                      nameError
                        ? "oneClick__input oneClick__input--error"
                        : "oneClick__input"
                    }
                    placeholder="Ваше ім’я"
                    onChange={onNameChange}
                    value={bayer_name}
                  />
                </div>
                <div className="oneClick__input--thumb">
                  <p className="oneClick__input--text">Номер телефону</p>
                  <input
                    type="text"
                    className={
                      phoneError
                        ? "oneClick__input oneClick__input--error"
                        : "oneClick__input"
                    }
                    placeholder="+38 (095) 555 - 55 - 55"
                    onClick={() => {
                      if (phone.trim() === "") {
                        setPhone("380");
                      } else if (phone === "38") {
                        setPhone("380");
                      } else if (phone === "3") {
                        setPhone("380");
                      }
                    }}
                    maxLength={13}
                    minLength={12}
                    onChange={onPhoneChange}
                    value={phone}
                  />
                </div>
              </div>
              <p className="oneClick__description">
                Менеджер передзвонить та допоможе оформити замовлення
              </p>
            </div>
            <button
              type="button"
              className="oneClick__button"
              onClick={() => {
                if (!bayer_name) {
                  setNameError(true);
                } else {
                  setNameError(false);
                }

                if (!phone || phone.length < 12) {
                  setPhoneError(true);
                } else {
                  setPhoneError(false);
                }
                const amount = (
                  item.quantity > 1
                    ? item.price * 0.8 + (item.quantity - 1) * item.price
                    : item.price
                ).toFixed(0);
                let comment = `${item.name}, колір ${item.color}, - загальна вартість ${amount} грн`;
                if (amount > 2499) {
                  comment += " - безкоштовна доставка";
                }

                if (bayer_name && phone && phone.length > 11) {
                  sendFastCallback(phone, bayer_name, comment, [
                    {
                      sku: item.id,
                      price: item.price,
                      quantity: item.quantity,
                      name: item.name,
                      discount_amount:
                        item.quantity > 1
                          ? (item.price * 0.2) / item.quantity
                          : 0,
                      properties: [
                        {
                          name: "Color",
                          value: item?.color,
                        },
                      ],
                    },
                  ]);
                  let pixelId = localStorage.getItem("pixel");
                  // Inject Facebook pixel script
                  const el = document.getElementById("pixelTagId8");
                  if (el) {
                    /* global fbq */ // Declare fbq as a global variable
                    // eslint-disable-next-line
                    !(function (f, b, e, v, n, t, s) {
                      if (f.fbq) return;
                      n = f.fbq = function () {
                        n.callMethod
                          ? n.callMethod.apply(n, arguments)
                          : n.queue.push(arguments);
                      };
                      if (!f._fbq) f._fbq = n;
                      n.push = n;
                      n.loaded = !0;
                      n.version = "2.0";
                      n.queue = [];
                      t = b.createElement(e);
                      t.async = !0;
                      t.src = v;
                      s = b.getElementsByTagName(e)[0];
                      s.parentNode.insertBefore(t, s);
                    })(
                      window,
                      document,
                      "script",
                      "https://connect.facebook.net/en_US/fbevents.js"
                    );
                    fbq("init", pixelId);
                    fbq("track", "Lead");
                    el.innerHTML = `<img alt='test' src=https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1 style=\"display:none\" width=\"1px\" height=\"1px\">`;
                  }
                  purchase({
                    id: generateRandomNumber(),
                    value: amount,
                    currency: "UAH",
                    items: [
                      {
                        id: item.id,
                        name: item.name,
                        price: item.price,
                        quantity: item.quantity,
                      },
                    ],
                    payment_method: "credit_card",
                  });
                  setSent(true);
                }
              }}
            >
              Передзвонити мені
            </button>
          </div>
        ) : (
          <div className="oneClick__success">
            <img src={success} alt="успішно" className="oneClick__image" />
            <p className="oneClick__success--text">Успішно!</p>
            <p className="oneClick__success--description">
              Ваш запит успішно надісланий. Очікуйте на дзвінок менеджера.
            </p>
            <button
              type="button"
              className="onClick__success--button"
              onClick={() => setFastCartOpen(false)}
            >
              Зрозуміло
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
